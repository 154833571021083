<!--
 * @Author: your name
 * @Date: 2021-11-29 16:20:40
 * @LastEditTime: 2021-12-13 15:06:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/index.vue
-->
<template>
    <div class="wb-fore">
        <SearchBar :formList="form1" @search="search" @reset="search"></SearchBar>
        <a-table size="small" bordered :columns="columns" :data-source="tableData" style="margin-top: 20px"
            :scroll="{ x: 'max-content' }" :rowKey="(record, index) => index" :pagination="pagination"
            @change="handlerPage" :row-selection="rowSelection" :customRow="customRow" :rowClassName="rowClassName">
            <template slot="driver" slot-scope="text">
                <span>{{ text.name || "-" }}</span>
                <a-popover placement="bottom">
                    <template slot="content">
                        <p>姓名：{{ text.name }}</p>
                        <p>电话：{{ text.phone }}</p>
                    </template>
                    <template slot="title">
                        <span>司机信息</span>
                    </template>
                    <a class="btn-a" type="link" style="margin-left: 10px" @click="seeShipment(record)">查看</a>
                </a-popover>
            </template>
            <template slot="otherDriver" slot-scope="text">
                <span>{{ text.name || "-" }}</span>
                <a-popover placement="bottom">
                    <template slot="content">
                        <p>姓名：{{ text.name }}</p>
                        <p>电话：{{ text.phone }}</p>
                        <p>预计时间：{{ text.expect_at }}</p>
                    </template>
                    <template slot="title">
                        <span>其他拉货方式</span>
                    </template>
                    <a class="btn-a" type="link" style="margin-left: 10px" @click="seeShipment(record)">查看</a>
                </a-popover>
            </template>
            <template slot="action" slot-scope="record">
                <a class="btn-a" type="link" @click="seeDetial(record)">查看详情</a>
                <a class="btn-a" type="link" @click="forClick(record)" v-if="record.status != 3">取消预报</a>
                <!-- <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="handlerCancel(record)">
                    <template slot="title">
                        <span>确定取消预报嘛？</span>
                    </template>
                    <a class="btn-a" type="link">取消预报</a>
                </a-popconfirm> -->
                <a class="btn-a" type="link" @click="handlerDelivery(record)"
                    v-if="record.type === 2 && (record.use_box_count < record.box_count)">发货</a>
                <a class="btn-a" @click="printFun(record)">打印</a>
            </template>
        </a-table>
        <a-drawer :title="modalObj.title" :visible="modalObj.visible" :width="modalObj.width" @close="handleCancel"
            :maskClosable="false">
            <!-- <ship ref="shipRef" :id="rowId" :limit="limit" v-if="modalObj.visible" @success="handlerDe" @fail="handlerFail"></ship> -->
            <div class="state">
                <p>当前货件数量：{{ totalCount }}</p>
                <p>可以发件最大数:{{ tickMsg.box_count }}</p>
                <p :style="{ color: totalCount > tickMsg.box_count ? '#c0392b' : '#95a5a6' }">
                    当前状态:{{ totalCount > tickMsg.box_count ? "超过最大发货数" : "未超出" }}</p>
            </div>
            <div class="ship" v-for="(item, index) in ticketForms" :key="index">
                <a-button type='link' v-if="index != 0" @click="delTicket(index)">删除此票</a-button>
                <ShipVersion2 @tickData='getTickData' ref="ship2" :form='item'></ShipVersion2>
            </div>
            <div class="drawerFooter">
                <a-button-group>
                    <a-button @click="handleCancel">
                        取消
                    </a-button>
                    <a-button @click="ticketFormsAdd" style="color:#fb540f;border:1px solid">
                        <a-icon type="plus" />添加一票
                    </a-button>
                    <a-button type="primary" @click="handleOk">
                        确定
                    </a-button>
                </a-button-group>
            </div>
        </a-drawer>
        <ly-drawer :title="ld.title" :visible="ld.show" :width="ld.width" @cancel="ld.show = false">
            <printContent :num="boxCount" :forecastNo="forecastNo" :forecastType="forecastType"
                v-if="ld.show && ld.index === 2"></printContent>
            <printWaybill :id="rowId" :forecastType="forecastType" @num="pwNum" v-if="ld.show && ld.index === 1">
            </printWaybill>
            <div slot="footer">
                <a-button-group>
                    <a-button @click="ld.show = false">
                        取消
                    </a-button>
                    <a-button @click="seePdf">打印箱唛</a-button>
                    <a-button :loading="ld.subLoading" type="primary" @click="handlerOk">
                        下载为PDF
                    </a-button>
                </a-button-group>
            </div>
        </ly-drawer>
        <a-drawer :title="drawer.title" :closable="true" :visible.sync="drawer.show" width="1000px" @close="onClose">
            <a-table bordered :columns="columns2" :data-source="waybillList" style="margin-top: 20px"
                :pagination="pagination2" @change="handlerPage2"
                :rowClassName="(record) => { if (record.finish_status == 2) return 'ff' }">
                <template slot="action2" slot-scope="record">
                    <a class="btn-a" type="link" @click="cancelForecast(record)"
                        v-if="record.finish_status != 2">取消预报</a>
                </template>
            </a-table>
            <div class="drawerFooter">
                <a-button-group>
                    <a-button @click="onClose">
                        取消
                    </a-button>
                    <a-button type="primary" @click="handlerSubmit">
                        全部取消
                    </a-button>
                </a-button-group>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {
    exportData
} from "@/api/Export"
import {
    forecastDelivery
} from "@/api/Waybill";
import columns2 from "./curd/columns2";
import columns from "./curd/columns";
import {
    forecastList,
    forecastCancel,
    forecastInfo,
    waybillCancel
} from "@/api/Waybill";
import SearchBar from "@/components/SearchBar"
import ShipVersion2 from "./curd/shipVersion2"
import printWaybill from "./curd/printWaybill"
import printContent from "./curd/printContent.vue"
import form1 from "./curd/form1.js"
export default {
    name: "Forecast",
    components: {
        ShipVersion2,
        printWaybill,
        printContent,
        SearchBar
    },
    data() {
        return {
            selectIndex: null,
            rowSelection: {},
            rowId: "",
            columns2,
            form1,
            printDialog: false,
            drawer: {
                title: "运单详情",
                show: false
            },
            totalCount: 0,
            tickMsg: {},
            limit: 1,
            columns: columns,
            tableData: [],
            waybillList: [],
            ticketForms: [{
                "countryId": '',
                "channelId": '',
                "boxCount": '',
                "remark": "",
                newMaterialCates: [],
            },],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            pagination2: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            modalObj: {
                title: "",
                visible: false,
                width: '700px'
            },
            form: {
                page: 1,
                limit: 20,
                status: 1
            },
            ld: {
                title: "",
                show: false,
                width: '',
                index: -1,
                subLoading: false
            },
            confirmLoading: false,
            forecastType: 1,
            forecastNo: "",
            boxCount: 0,
            chooseRow: {}
        };
    },
    watch: {
        ticketForms: {
            handler(val) {
                this.totalCount = 0
                for (let i of val) {
                    this.totalCount += i.boxCount
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.getData();

    },

    methods: {
        // 点击的样式
        rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
        search(val) {
            this.form = Object.assign(this.form, val)
            this.getData()
        },
        //打印
        forClick(row, bool = true) {
            this.drawer.show = bool
            forecastInfo({
                forecastId: row.id
            }).then(res => {
                this.infoData = res.data
                this.waybillList = res.data.waybills || []
                this.pagination2.total = res.data.waybills ? res.data.waybills.length : 0
            })
        },
        cancelForecast(row) {
            let that = this
            this.$confirm({
                title: '取消预报',
                content: `正在对运单${row.waybill_no}进行取消预报操作，请您再次确认是否取消预报。`,
                onOk() {
                    waybillCancel({
                        waybillId: row.id
                    }).then(() => {
                        that.$message.success("取消成功")
                        forecastInfo({
                            forecastId: that.infoData.id
                        }).then(res => {
                            that.infoData = res.data
                            that.waybillList = res.data.waybills || []
                            that.pagination2.total = res.data.waybills ? res.data.waybills
                                .length : 0
                            that.getData();

                        })
                    })
                },
            });
        },
        handlerSubmit() {
            let that = this
            this.$confirm({
                title: '取消预报',
                content: `正在对预报单“${this.infoData.forecast_no}”进行取消预报操作，请您再次确认是否取消预报。`,
                onOk() {
                    let obj = {
                        forecastId: that.infoData.id,
                    };
                    forecastCancel(obj).then(() => {
                        that.$message.success("取消成功");
                        that.drawer.show = false
                        that.getData();
                    });
                },
            });
        },
        onClose() {
            this.drawer.show = false
        },
        handlerPage2(p) {
            this.pagination2.current = p.current;
            this.pagination2.pageSize = p.pageSize;
        },
        // 查看详情
        seeDetial(row) {
            this.$router.push({
                path: "/Waybill/Forecast/_details",
                query: {
                    id: row.id,
                    status: row.status
                }
            });
        },
        // 切换分页
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        // 获取列表数据
        getData() {
            forecastList(this.form).then((res) => {
                this.tableData = res.data.list;
                this.pagination.total = res.data.total;
            });
        },
        // 取消预报
        handlerCancel(row) {
            let obj = {
                forecastId: row.id,
            };
            forecastCancel(obj).then(() => {
                this.$message.success("取消成功");
                this.getData();
            });
        },
        // 查看pdf
        seePdf() {
            window.open(process.env.VUE_APP_PDF_URL + `/forecast/cargo/` + this.chooseRow.uuid);
        },
        // 发货
        handlerDelivery(row) {
            this.tickMsg.box_count = row.box_count - row.use_box_count
            this.rowId = row.id;
            this.modalObj.title = "发货";
            this.limit = row.box_count - row.use_box_count;
            this.modalObj.visible = true;
        },
        // 关闭model
        handleCancel() {
            this.modalObj.visible = false;
        },
        // 提交
        handleOk() {
            this.confirmLoading = true;
            let porAll = []
            for (let i of this.$refs.ship2) {
                porAll.push(i.submit())

            }
            if (this.totalCount > this.tickMsg.box_count) {
                this.$message.error("超出最大发货数")
                return
            }
            Promise.all(porAll).then(() => {
                forecastDelivery({
                    forecastId: this.rowId,
                    tickets: this.ticketForms
                }).then(() => {
                    this.$message.success('发货成功')
                    this.ticketForms = [{
                        "countryId": '',
                        "channelId": '',
                        "boxCount": '',
                        "remark": "",
                        newMaterialCates: [],
                    },],
                        this.getData();

                    this.handleCancel()
                })
            })


        },
        // 发货成功
        handlerDe() {
            this.confirmLoading = false;
            this.modalObj.visible = false;
            this.getData();
        },
        // 发货失败
        handlerFail() {
            this.confirmLoading = false;
        },
        //获取票数据
        getTickData() { },
        //删除票
        delTicket(index) {
            this.ticketForms.splice(index, 1)
        },
        //调价票
        ticketFormsAdd() {
            let obj = {
                "countryId": "",
                "channelId": "",
                "boxCount": "",
                "remark": "",
                newMaterialCates: [],
            }
            this.ticketForms.push(obj)
        },
        printFun(row) {
            if (row.type === 1) {
                this.ld.index = 1;
                this.rowId = row.id;
                this.forClick(row, false)
            } else if (row.type === 2) {
                this.ld.index = 2;
                this.boxCount = row.box_count;
                this.forecastNo = row.forecast_no;
            }
            this.forecastType = row.type;
            this.ld.width = '400px';
            this.ld.title = "打印"
            this.ld.show = true;
            this.chooseRow = row
        },
        pwNum(n) {
            this.boxCount = n;
        },
        handlerOk() {
            this.ld.subLoading = true;
            this.printDialog = true
            if (this.forecastType == 1) {
                for (let i of this.waybillList) {
                    exportData({
                        fileName: i.waybill_no + "+" + i.cargoes_num + "箱" + "+" + i.channel_name,
                        fileType: 'pdf',
                        params: {
                            waybillIds: [i.id],
                        },
                        url: '/order/waybill/cargoes/pdf'
                    }).then(() => {
                        this.printDialog = false
                        this.ld.subLoading = false;

                    }).catch(() => {

                        this.printDialog = false
                        this.ld.subLoading = false;

                    })
                }
            } else {
                exportData({
                    fileName: this.chooseRow.forecast_no + "+" + this.chooseRow.box_count + "箱",
                    fileType: 'pdf',
                    params: {
                        forecastId: this.chooseRow.id,
                    },
                    url: '/order/forecast/cargoes/pdf'
                }).then(() => {
                    this.printDialog = false
                    this.ld.subLoading = false;
                }).catch(() => {

                    this.ld.subLoading = false;
                    this.printDialog = false

                })
            }

        }
    },
};
</script>

<style lang="less" scoped>
.state {
    display: flex;
    justify-content: space-between
}

/deep/.ff {
    color: #999999;
}
</style>
/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-03 11:33:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
import moment from "moment";
export default [
    {
        title: '运单号',
        dataIndex: 'waybill_no',
        key: 'waybill_no',
    },
    {
        title: '件数',
        dataIndex: 'cargoes_num',
        key: 'cargoes_num',
    },
    {
        title: '国家',
        dataIndex: 'country_name',
        key: 'country_name',
    },
    {
        title: '渠道',
        dataIndex: 'channel_name',
        key: 'channel_name',
    },
    {
        title: '备注',
        dataIndex: 'remake',
        key: 'remake',
    },
    {
        title: '操作',
        key: 'action2',
        scopedSlots: { customRender: 'action2' },
      },
]


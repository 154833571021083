<template>
  <div class="pc-outer">
    <div
      style="border-top: 1px solid #fff; width: 280px"
      v-for="(item2, index2) in list"
      :key="index2"
      :id="item2.key"
    >
      <div
        class="pc-container"
        v-for="(item, index) in item2.value"
        :key="index"
      >
        <div class="pc-d1" style="padding: 5px">
          {{ item.no }}
        </div>
        <div class="pc-d2">
          <div class="pc-d2-dd1">
            <div class="pdd-s1">
              {{ item.countryName }}
            </div>
          </div>
          <div class="pc-d2-dd2">
            <div class="pdd-s2">
              {{ item.num }}
            </div>
            <div class="pdd-s3">
              {{ item.cargoesNum }}
            </div>
            <div class="pdd-line"></div>
          </div>
        </div>
        <div class="pc-d1-d1">
          <div
            class="pc-d1-d1-in"
            :style="{
              'font-size': item.channelName.length > 13 ? '18px' : '24px',
            }"
          >
            {{ item.channelName }}
          </div>
        </div>
        <div class="pc-d1-d1" style="height: 65px">
          <div
            class="pc-d1-d1-in-remark"
            :style="{
              'font-size': calcSize(item.remark.length),
              'align-items': item.remark.length >= 66 ? 'none' : 'center',
            }"
          >
            {{ item.remark }}
          </div>
        </div>
        <div class="pc-d3">
          <!-- <svg v-barcode="createCode(item.no, item.num)"></svg> -->
          <div
            style="margin-top: 8px"
            v-qrcode="{
              no: createBoxNo(item.no, item.num),
              width: '50',
              height: '50',
            }"
          ></div>
        </div>
        <div style="text-align: center; font-weight: 600">
          {{ createBoxNo(item.no, item.num) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forecastInfo } from "@/api/Waybill";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    forecastType: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    id: {
      handler(val) {
        forecastInfo({
          forecastId: val,
        }).then((res) => {
          let waybills = res.data.waybills || [];
          const arr = [];
          waybills = waybills.filter((ele) => {
            return ele.finish_status != 2;
          });

          waybills.forEach((ele) => {
            let kv = {
              key: ele.waybill_no,
              value: [],
            };
            let wNo = ele.waybill_no;
            let num = ele.cargoes_num;
            for (let i = 0; i < num; i++) {
              let obj = {
                no: wNo,
                num: i + 1,
                cargoesNum: num,
                countryName: ele.country_name,
                channelName: ele.channel_name,
                remark: ele.remark || "",
                id: ele.id,
              };
              kv.value.push(obj);
            }
            arr.push(kv);
          });
          this.list = arr;
          console.log(this.list);
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].value.length > 55) {
              let spliceArr = [];
              for (
                let j = 1;
                j <= Math.ceil(this.list[i].value.length / 55);
                j++
              ) {
                let obj = {
                  key: this.list[i].key + "_" + j,
                };
                obj.value = this.list[i].value.slice(
                  (j - 1) * 55,
                  (j - 1) * 55 + 55
                );
                spliceArr.push(obj);
              }
              this.list.splice(i, 1, ...spliceArr);
              i--;
            }
          }
          console.log(this.list);
          this.$emit("num", this.list.length);
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    calcSize(len) {
      let remarkSize = 24;
      if (len > 44) {
        remarkSize = 12;
      } else if (len > 22 && len <= 44) {
        remarkSize = 14;
      } else if (len > 13 && len <= 22) {
        remarkSize = 18;
      } else {
        remarkSize = 24;
      }
      return remarkSize + "px";
    },
    createCode(s, num) {
      let no = s + this.noStr(num, 4);
      let w = (no.length / 280) * 20;
      return {
        no: no,
        width: w,
        height: 20,
      };
    },
    createBoxNo(s, num) {
      return s + this.noStr(num, 4);
    },
    // 位数处理
    noStr(num, n) {
      let str = num + "";
      let s1 = "";
      let m = n - str.length;
      if (m > 0) {
        for (let i = 0; i < m; i++) {
          s1 += "0";
        }
      }
      s1 += str;
      return s1;
    },
  },
  filters: {
    forecastTypeStr(val) {
      let str = "";
      if (val == 1) {
        str = "计划下单";
      } else if (val == 2) {
        str = "无计划下单";
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.pc-outer {
  height: auto;
  background: #fff;
  color: #000000;
  padding: 10px;
  border-radius: 2px;
}

.pc-container {
  width: 280px;
  //   height: 300px;
  border: 1px solid #eff0f1;
}

.pc-d1 {
  border-bottom: 1px solid #eff0f1;
  text-align: center;
  font-weight: 500;
  font-size: 26px;
}

.pc-d2 {
  display: flex;
  height: 50px;
  justify-content: flex-start;
  overflow: hidden;
  border-bottom: 1px solid #eff0f1;
}

.pc-d3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pc-d2-dd1 {
  display: flex;
  width: 170px;
  height: 100%;
  border-right: 1px solid #eff0f1;
  justify-content: flex-start;
  align-items: center;

  .pdd-s1 {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}

.pc-d2-dd2 {
  position: relative;
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;

  .pdd-s2 {
    width: 50%;
    height: 50px;
    text-align: center;
    line-height: 50px;
  }

  .pdd-s3 {
    width: 50%;
    height: 50px;
    text-align: center;
    line-height: 50px;
  }

  .pdd-line {
    position: absolute;
    width: 40px;
    height: 1px;
    background: #9f9f9f;
    top: 50%;
    left: 33%;
    transform: rotate(-45deg);
  }
}

.pc-d1-d1 {
  border-bottom: 1px solid #eff0f1;
  font-weight: 500;
  height: 50px;
  overflow: hidden;

  .pc-d1-d1-in {
    text-align: center;
    position: relative;
    // left: 50%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 5px 0 5px;
  }
  .pc-d1-d1-in-remark {
    position: relative;
    line-height: normal;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 50px;
    padding: 0 5px 0 5px;
  }
}
</style>

<template>
    <div>

        <a-form-model :model="myForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="forms">
            <a-form-model-item prop="countryId" :rules="validate({ name: 'value', msg: '请选择国家' })" label='国家'>
                <a-select v-model="myForm.countryId" @change="countryChange" show-search optionFilterProp="label">
                    <a-select-option v-for="(item, index) in countryData" :key="index" :value="item.id"
                        :label="item.name">{{ item.name }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item prop="channelId" :rules="validate({ name: 'value', msg: '请选择渠道' })" label='渠道'>
                <a-select v-model="myForm.channelId" show-search optionFilterProp="label">
                    <a-select-option v-for="(item, index) in channelData" :key="index" :value="item.id"
                        :label="item.name">{{ item.name }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label='货件数量' prop='boxCount' :rules="validate({ name: 'value', msg: '请输入货件数量' })">
                <a-input-number placeholder="请输入货件数量" v-model="myForm.boxCount" style="width:100%"></a-input-number>
            </a-form-model-item>
            <a-form-model-item label='备注'>
                <a-input type='textarea' placeholder="选填" v-model="myForm.remark"></a-input>
            </a-form-model-item>
            <a-form-model-item label='品名'>
                <a-input type='textarea' placeholder="选填" v-model="myForm.newProductName"></a-input>
            </a-form-model-item>
            <a-form-model-item label="材质" prop="newMaterialCates" :rules="validate({ name: 'value', msg: '请选择材质' })">
                <a-checkbox-group v-model="myForm.newMaterialCates" :options="plainOptions">
                    <span slot="label" slot-scope="{ value }" style="color: red">{{ value }}</span>
                </a-checkbox-group>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
    import {
        countryList,
        channelCountry,materialList
    } from "@/api/comm";
    export default {
        props: {
            form: {
                type: Object,
                default: () => {}
            },
        },
        watch: {
            form: {
                handler(val) {
                    this.myForm = val
                    // this.myForm.newMaterialCates =[]
                },
                immediate: true
            }
        },
        data() {
            return {
                labelCol: {
                    span: 3
                },
                wrapperCol: {
                    span: 16
                },
                countryData: [],
                channelData: [],
                myForm: {
                    newMaterialCates:[],
                    remark:'',
                    countryId:"",channelId:'',boxCount:''
                },
                plainOptions:[]
            }
        },
        created() {
            countryList().then((res) => {
                this.countryData = res.data.list;
            });
            materialList().then(res => {
                for (let i of res.data) {
                    if (['普货', '带电', '带磁', '其他'].indexOf(i.name) != -1) {
                        if (i.name == '普货') {
                            this.myForm.newMaterialCates.push(i.id)
                        }
                        this.plainOptions.push({
                            label: i.name,
                            value: i.id
                        })
                    }
                }
            })
        },
        methods: {
            countryChange(val) {
                channelCountry({
                    countryId: val,
                }).then((res) => {
                    this.channelData = res.data;
                });
            },
            submit() {
                return new Promise((res, rej) => {
                    this.$refs.forms.validate((val) => {
                        if (val) {
                            this.$emit('tickData', this.myForm)
                            res(true)
                        } else {
                            this.$message.warning("请填写完整数据")
                            rej(false)
                        }
                    })
                })


            }
        },
    }
</script>
<style lang="scss" scoped>

</style>
<!--
 * @Author: your name
 * @Date: 2021-11-22 15:56:04
 * @LastEditTime: 2021-12-03 17:36:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/index.vue
-->
<template>
    <div class="page-container">
        <a-tabs type="card" @change="callback" :default-active-key="getWaybillIndex">
            <a-tab-pane key="2" :tab="'预报'+'('+totalData[0]+')'">
                <Forecast v-if="getWaybillIndex == 2"></Forecast>
            </a-tab-pane>
            <a-tab-pane key="9" :tab="'已取消预报'+'('+totalData[1]+')'">
                <ForecastCancelled v-if="getWaybillIndex == 9"></ForecastCancelled>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import Forecast from "./Forecast"
    import {
        forecastList,
        invoiceList,
        waybillCreateList,
        warehousingList,
        shipmentList,
        finishList,
        waybillAll
    } from "@/api/Waybill";
    import ForecastCancelled from "./ForecastCancelled"
    export default {
        name: "Waybill",
        components: {
            Forecast,
            ForecastCancelled
        },
        data() {
            return {
                totalData: []
            }
        },
        created() {
            let arr = [

                forecastList({
                    page: 1,
                    limit: 20,
                    status: 1
                }),

                forecastList({
                    "page": 1,
                    "limit": 20,
                    "status": 2
                }),
                // invoiceList({
                //     page: 1,
                //     limit: 20,
                // }),


                // waybillCreateList({
                //     page: 1,
                //     limit: 20,
                // }),


                // warehousingList({
                //     page: 1,
                //     limit: 20,
                // }),



                // shipmentList({
                //     page: 1,
                //     limit: 20,
                // }),


                // finishList({
                //     page: 1,
                //     limit: 20,
                // }),

                // waybillAll({
                //     page: 1,
                //     limit: 20,
                // }),


            ]
            Promise.all(arr).then(res => {
                for(let i of res){
                    this.totalData.push(i.data.total)
                }
            })
            this.callback(2)
        },
        methods: {
            callback(val) {
                this.setWaybillIndex(val);
            }
        }
    };
</script>

<style lang="less" scoped>
    /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
        color: black !important;
    }
</style>
/*
 * @Author: your name
 * @Date: 2022-02-09 15:08:46
 * @LastEditTime: 2022-02-24 10:58:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /deploy_frontend/src/api/Export/index.js
 */

import axios from 'axios';
import store from '@/store'
function doExport(response, fileName, fileType) {
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
    let filename = fileName + '.' + fileType;
    if (window.navigator.msSaveOrOpenBlob) {// 兼容IE10
        navigator.msSaveBlob(blob, filename);
    } else {
        // 创建一个超链接，将文件流赋进去，然后实现这个超链接的单击事件
        const elink = document.createElement('a');
        elink.download = filename;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
    }
}
export function exportData({fileName, fileType, url, params = {}}) {
    return new Promise((resolve, reject) => {
        axios({
            url: process.env.VUE_APP_API_URL +url,
            method: 'post',
            data: params,
            headers: {
                'ansuex-user-token': store.getters.getToken
            },
            responseType: 'blob'
        }).then(response => {
            console.log(response)
            doExport(response, fileName, fileType)
            resolve(true)
        }).catch(() => {
            reject(false)
        })
    })

}

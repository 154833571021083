export default[
    {
        type:'textarea',
        label:"预报单号",
        prop:'forecastNo'
    },
    {
        type:'textarea',
        label:"运单号",
        prop:'waybillNo'
    },
]
<!--
 * @Author: your name
 * @Date: 2021-11-29 16:20:40
 * @LastEditTime: 2021-12-13 15:06:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/index.vue
-->
<template>
    <div class="wb-fore">
        <SearchBar :formList="form1" @search="search" @reset="search"></SearchBar>
        <a-table size="small" bordered :columns="columns" :data-source="tableData" style="margin-top: 20px" :scroll="{ x:'max-content' }"
            :rowKey="(record,index) => index" :pagination="pagination" @change="handlerPage" :row-selection="rowSelection" :customRow="customRow" :rowClassName="rowClassName">
            <template slot="driver" slot-scope="text">
                <span>{{text.name || "-"}}</span>
                <a-popover placement="bottom">
                    <template slot="content">
                        <p>姓名：{{text.name}}</p>
                        <p>电话：{{text.phone}}</p>
                    </template>
                    <template slot="title">
                        <span>司机信息</span>
                    </template>
                    <a class="btn-a" type="link" style="margin-left: 10px" @click="seeShipment(record)">查看</a>
                </a-popover>
            </template>
            <template slot="otherDriver" slot-scope="text">
                <span>{{text.name || "-"}}</span>
                <a-popover placement="bottom">
                    <template slot="content">
                        <p>姓名：{{text.name}}</p>
                        <p>电话：{{text.phone}}</p>
                        <p>预计时间：{{text.expect_at}}</p>
                    </template>
                    <template slot="title">
                        <span>其他拉货方式</span>
                    </template>
                    <a class="btn-a" type="link" style="margin-left: 10px" @click="seeShipment(record)">查看</a>
                </a-popover>
            </template>
            <template slot="action" slot-scope="record">
                <a class="btn-a" type="link" @click="seeDetial(record)">查看详情</a>
            </template>
        </a-table>
    </div>
</template>

<script>
    import columns from "./curd/columns";
    import {
        forecastList
    } from "@/api/Waybill";
    import form1 from "./curd/form1.js"
    import SearchBar from "@/components/SearchBar"
    export default {
        name: "Forecast",
        components: {
            SearchBar
        },
        data() {
            return {
                selectIndex: null,
                rowSelection:{},
                rowId: "",
                limit: 1,
                columns: columns,
                form1,
                tableData: [],
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 20,
                    showTotal: (total) => `共 ${total} 条`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                },
                modalObj: {
                    title: "",
                    visible: false,
                    width: '700px'
                },
                form: {
                    page: 1,
                    limit: 20,
                    status: 2
                },
                confirmLoading: false

            };
        },
        created() {
            this.getData();

        },
        methods: {
            // 点击的样式
        rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
            search(val) {
                this.form = Object.assign(this.form, val)
                this.getData()
            },
            reset(val) {
                this.form = Object.assign(this.form, val)
                this.getData()
            },
            // 查看详情
            seeDetial(row) {
                this.$router.push({
                    path: "/Waybill/ForecastCancelled/_details",
                    query: {
                        id: row.id
                    }
                });
            },
            // 切换分页
            handlerPage(p) {
                this.form.page = p.current;
                this.form.limit = p.pageSize;
                this.pagination.current = p.current;
                this.pagination.pageSize = p.pageSize;
                this.getData();
            },
            // 获取列表数据
            getData() {
                forecastList(this.form).then((res) => {
                    this.tableData = res.data.list;
                    this.pagination.total = res.data.total;
                });
            }
        },
    };
</script>

<style lang="less" scoped>
</style>
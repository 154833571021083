<template>
  <div class="pc-outer">
    <div id="pdfDom" style="border-top: 1px solid #fff">
      <div class="pc-container" v-for="item in num" :key="item">
        <div class="pc-d1">
          {{ forecastNo }}
        </div>
        <div class="pc-d2">
          <div class="pc-d2-dd1">
            <div class="pdd-s1">
              {{ forecastType | forecastTypeStr }}
            </div>
          </div>
          <div class="pc-d2-dd2">
            <div class="pdd-s2">
              {{ item }}
            </div>
            <div class="pdd-s3">{{ num }}</div>
            <div class="pdd-line"></div>
          </div>
        </div>
        <div class="pc-d3">
          <!-- <svg  v-barcode="createCode(item)" /> -->
          <div
            style="margin-top: 8px"
            v-qrcode="{ no: createBoxNo(item), width: '100', height: '100' }"
          ></div>
        </div>
        <div style="text-align: center; font-weight: 600; font-size: 18px">
          {{ createBoxNo(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "print-content",
  props: {
    num: {
      type: Number,
      default: 0,
    },
    forecastNo: {
      type: String,
      default: "",
    },
    forecastType: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    createCode(num) {
      let no = this.forecastNo + this.noStr(num, 4);
      let w = (no.length / 280) * 20;
      return {
        no: no,
        width: w,
      };
    },
    createBoxNo(num) {
      return this.forecastNo + this.noStr(num, 4);
    },
    // 位数处理
    noStr(num, n) {
      let str = num + "";
      let s1 = "";
      let m = n - str.length;
      if (m > 0) {
        for (let i = 0; i < m; i++) {
          s1 += "0";
        }
      }
      s1 += str;
      return s1;
    },
  },
  filters: {
    forecastTypeStr(val) {
      let str = "";
      if (val == 1) {
        str = "计划下单";
      } else if (val == 2) {
        str = "无计划下单";
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
#pdfDom {
  width: 280px;
}
.pc-outer {
  height: auto;
  background: #fff;
  padding: 10px;
  border-radius: 2px;
}

.pc-container {
  width: 280px;
  //   height: 280px;
  border: 1px solid #eff0f1;
}

.pc-d1 {
  border-bottom: 1px solid #eff0f1;
  padding: 17px;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
}

.pc-d2 {
  display: flex;
  height: 100px;
  justify-content: flex-start;
  overflow: hidden;
  border-bottom: 1px solid #eff0f1;
}
.pc-d3 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pc-d2-dd1 {
  display: flex;
  width: 170px;
  height: 100%;
  border-right: 1px solid #eff0f1;
  justify-content: flex-start;
  align-items: center;
  .pdd-s1 {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}

.pc-d2-dd2 {
  position: relative;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;

  .pdd-s2 {
    width: 50%;
    height: 40px;
    text-align: center;
  }

  .pdd-s3 {
    width: 50%;
    height: 100%;
    text-align: center;
    line-height: 120px;
  }

  .pdd-line {
    position: absolute;
    width: 100px;
    height: 1px;
    background: #000;
    top: 50px;
    left: 0px;
    transform: rotate(-45deg);
  }
}

.pc-d3 {
}
</style>

/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-03 11:33:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
import moment from "moment";
export default [
    {
        title: '预报类型',
        dataIndex: 'type',
        key: 'type',
        customRender: (text) => {
            let str = ""
            if(text === 1) {
                str = "计划"
            } else if(text === 2) {
                str = "未建计划"
            }
            return str;
        }
    },
    {
        title: '预报单号',
        dataIndex: 'forecast_no',
        key: 'forecast_no',
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        customRender: (text) => {
            let str = ""
            if(text === 1) {
                str = "待收货"
            } else if(text === 2) {
                str = "已收货"
            } else if(text === 3) {
                str = "已入库"
            }
            return str;
        }
    },
    {
        title: '预报日期',
        dataIndex: 'created_at',
        key: 'created_at',
        customRender: (text) => {
            let str = ""
            if(text) {
                str = moment(text*1000).format("YYYY-MM-DD HH:mm:ss")
            } else {
                str = "-"
            }
            return str;
        }
    },
    {
        title: '票数',
        dataIndex: 'waybill_count',
        key: 'waybill_count',
    },
    {
        title: '件数',
        dataIndex: 'box_count',
        key: 'box_count',
    },
    {
        title: '收件方式',
        dataIndex: 'receive_type',
        key: 'receive_type',
        customRender: (text) => {
            let str = ""
            if(text === 1) {
                str = "上门取件"
            } else if(text === 2) {
                str = "自送入仓"
            }
            return str;
        }
    },
    {
        title: '已发货物件数',
        dataIndex: 'use_box_count',
        key: 'use_box_count',
    },
    {
        title: '收货类型',
        dataIndex: 'receipt_type',
        key: 'receipt_type',
        customRender: (text) => {
            let str = "-"
            if(text === 1) {
                str = "扫码收货"
            } else if(text === 2) {
                str = "直接收货"
            } else if(text === 3) {
                str = "客户直送"
            } else if(text === 4) {
                str = "货拉拉"
            }
            return str;
        }
    },
    {
        title: '司机信息',
        dataIndex: 'driver',
        key: 'driver',
        scopedSlots: { customRender: 'driver' },
    },
    // {
    //     title: '其他拉货方式',
    //     dataIndex: 'other_driver',
    //     width: 160,
    //     key: 'other_driver',
    //     scopedSlots: { customRender: 'otherDriver' },
    // },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    },
]

